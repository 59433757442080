var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-container"},[_c('v-row',{staticClass:"uk-container uk-margin-large-top"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"uk-container uk-margin-large-top"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"font-weight-bold",style:({ color: this.fontWeightBold })},[_c('base-arrow-back-icon',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.rightArrowIcon))]),_vm._v(" الدورات قيد الانتظار ")],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('table-actions-nav',{attrs:{"type":"course","data":{name: 'Pending Courses', data: _vm.pendingCourses},"options":{
              withAddNew: true,
              withSearch: true,
              withExport: true,
            }},model:{value:(_vm.search1),callback:function ($$v) {_vm.search1=$$v},expression:"search1"}})],1)],1),_c('v-row',{staticClass:"uk-margin-small-top"},[[_c('v-data-table',{staticClass:"elevation-1 w-100",attrs:{"headers":_vm.liveCoursesHeaders,"items":_vm.pendingCourses,"items-per-page":5,"search":_vm.search1,"footer-props":{
              itemsPerPageText: 'عدد الأسطر في الجدول:',
            },"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('table-actions',{attrs:{"status":item.status,"type":item.type,"course":""},on:{"remove":function($event){return _vm.remove(item.course_id)},"edit":function($event){return _vm.edit(item.course_id)},"show":function($event){return _vm.show(item.course_id)},"courses":function($event){return _vm.goTo(item.course_id)},"offline":function($event){return _vm.offline(item.course_id)}}})]}}],null,true)})]],2)],1)],1),_c('v-row',{staticClass:"uk-container uk-margin-large-top"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"font-weight-bold",style:({ color: this.fontWeightBold })},[_vm._v(" الدورات النشطة ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('table-actions-nav',{attrs:{"type":"course","data":{name: 'Live Courses', data: _vm.liveCourses},"options":{ withAddNew: false, withSearch: true, withExport: true }},model:{value:(_vm.search3),callback:function ($$v) {_vm.search3=$$v},expression:"search3"}})],1)],1),_c('v-row',{staticClass:"uk-margin-small-top"},[[_c('v-data-table',{staticClass:"elevation-1 w-100",attrs:{"headers":_vm.liveCoursesHeaders,"items":_vm.liveCourses,"items-per-page":5,"search":_vm.search3,"footer-props":{
          itemsPerPageText: 'عدد الأسطر في الجدول:',
        },"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('table-actions',{attrs:{"status":item.status,"type":item.type,"course":""},on:{"remove":function($event){return _vm.remove(item.course_id)},"edit":function($event){return _vm.edit(item.course_id)},"show":function($event){return _vm.show(item.course_id)},"courses":function($event){return _vm.goTo(item.course_id)},"offline":function($event){return _vm.offline(item.course_id)}}})]}}],null,true)})]],2),(_vm.statusConfirm >= 0)?_c('confirm-status',_vm._b({on:{"handleClose":function($event){_vm.statusConfirm = -1},"handelRequest":_vm.resubmit}},'confirm-status',{ id: _vm.statusConfirm, isOpen: true },false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }