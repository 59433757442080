<template>
  <v-btn @click="click()" :color="this.primaryColor" class="mx-4" icon>
    <v-icon x-large name="icon">
      <slot></slot>
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BaseArrowBackIcon",
  methods : {
    click(){
      this.$emit('click')
    }
  }
}
</script>

<style scoped>

</style>
