<template>
  <v-row
    class="d-flex align-center uk-margin-auto"
    style="justify-content: flex-end !important"
  >
    <download-excel
      :data="data.data"
      :fields="data.fields"
      :name="`${data.name}.xls`"
    >
      <v-btn
        class="white--text ma-2"
        v-if="options.withExport"
        :color="this.YColor"
      >
        <v-icon>{{ this.downloadIcon }}</v-icon>
        تصدير
      </v-btn>
    </download-excel>
    <v-btn
      class="white--text ma-2"
      v-if="options.withAddNew"
      @click="routerEnter"
      :color="this.primaryColor"
    >
      <v-icon>{{ this.plusIcon }}</v-icon>
      اضافة جديد
    </v-btn>
    <form uk-grid v-if="options.withSearch" class="uk-search uk-search-default">
      <div class="uk-width-auto uk-position-relative">
        <span
          class="uk-search-icon-flip"
          :style="{ color: this.primaryColor }"
          uk-search-icon
        ></span>
        <input
          class="uk-search-input"
          v-model="search"
          type="search"
          @input="$emit('input', search)"
          placeholder="البحث"
        />
      </div>
    </form>
  </v-row>
</template>

<script>
export default {
  name: "TableActionsNav",
  props: {
    options: Object,
    type: String,
    data: Object,
  },
  methods: {
    routerEnter() {
      if (this.type == "course") this.$router.push({ name: "addNewCourse" });
      else if (this.type == "module")
        this.$router.push({
          name: "addNewModule",
          params: { id: this.$route.params.id },
        });
      else if (this.type == "lecture")
        this.$router.push({
          name: "addNewLecture",
          params: { id: this.$route.params.id },
        });
    },
  },
  data() {
    return {
      search: "",
    };
  },
};
</script>

<style scoped>
</style>
