<template>
  <v-row align="center" justify="center">

    <div  v-if="!(status === 'denied' || status === 'مرفوض')" class="text-center">
      <v-menu offset-y open-on-hover min-width="150">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="primaryColor"
            icon
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon large>{{ settingsIcon }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="course && (status === 'resubmit' || status === 'إعادة الإرسال')" @click="resubmit">
            <v-list-item-title>إعادة الإرسال</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="course" @click="courses">
            <v-list-item-title>الوحدات</v-list-item-title>
          </v-list-item>
          <v-list-item @click="edit">
            <v-list-item-title>تعديل</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="course && (type==='offline' || type==='حضورية')" @click="ofline">
            <v-list-item-title>معلومات الحضور</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="course" @click="show">
            <v-list-item-title>تفاصيل</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="module" @click="modules">
            <v-list-item-title>الدروس</v-list-item-title>
          </v-list-item>
          <router-link v-if="module" :to="{name : 'instructorModuleQuestions' , params : {id : id} }">
            <v-list-item>
              <v-list-item-title>الأسئلة</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-list-item @click="isOpen = true">
            <v-list-item-title>حذف</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    
    <!-- <v-btn text icon color="secondary lighten-2" v-if="course" @click="ofline">
      <v-icon color="rgb(255, 169, 80)">{{ this.personalIcon }}</v-icon>
    </v-btn>
    <v-btn text icon color="secondary lighten-2" @click="edit">
      <v-icon color="blue">{{ this.editIcon }}</v-icon>
    </v-btn>
    <v-btn text icon color="secondary lighten-2" @click="show">
      <v-icon color="green">{{ this.eyeIcon }}</v-icon>
    </v-btn>
    <v-btn text icon color="secondary lighten-2" v-if="module" @click="modules">
      <v-icon color="rgb(255, 169, 80)">{{ this.pieIcon }}</v-icon>
    </v-btn>
    <router-link v-if="module" :to="{name : 'instructorModuleQuestions' , params : {id : id} }">
      <v-icon>{{ qustionIcon }}</v-icon>
    </router-link>
    <v-btn text icon color="secondary lighten-2" @click="isOpen = true">
      <v-icon color="red">{{ this.deleteIcon }}</v-icon>
    </v-btn> -->
    <v-dialog persistent max-width="600px" v-model="isOpen">
      <v-card max-height="100%" class="confirm-delete">
        <v-card-title class="headline mb-9"> هل تريد الحذف بالتأكيد ؟ </v-card-title>
        <v-card-actions>
          <v-row class="d-flex flex-column flex-md-row justify-space-around align-center my-3">
              <base-button
                class="text-large my-2"
                :options="{ block: false, color: RColor }"
                @click="remove"
              >
                نعم
              </base-button>
              <base-button
                class="text-large my-2"
                :options="{ block: false }"
                @click="isOpen = false"
              >
                الغاء
              </base-button>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BaseButton from "@/core/Base/Buttons/BaseButton";

export default {
  name: "TableActions",
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    id: Number,
    course: Boolean,
    module: Boolean,
    status: String,
    type: String
  },
  components: {
    BaseButton,
  },
  methods: {
    remove() {
      this.$emit("remove");
      this.isOpen = false;
    },
    edit() {
      this.$emit("edit");
    },
    show() {
      this.$emit("show");
    },
    courses() {
      this.$emit("courses");
    },
    modules() {
      this.$emit("modules");
    },
    ofline(){
      this.$emit("offline")
    },
    resubmit(){
      this.$emit("resubmit")
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.text-large {
  font-size: 18px !important;
}
button {
  width: 200px !important;
}

.confirm-delete {
  overflow: hidden !important;
}
</style>
