<template>
  <div class="uk-container">
    <v-row class="uk-container uk-margin-large-top">
      <v-col cols="12">
        <v-row class="uk-container uk-margin-large-top">
          <v-col cols="12">
            <h1
              class="font-weight-bold"
              :style="{ color: this.fontWeightBold }"
            >
              <base-arrow-back-icon @click="$router.go(-1)">{{
                rightArrowIcon
              }}</base-arrow-back-icon>
              الدورات قيد الانتظار
            </h1>
          </v-col>
          <v-col cols="12">
            <table-actions-nav
              type="course"
              v-model="search1"
              :data="{name: 'Pending Courses', data: pendingCourses}"
              :options="{
                withAddNew: true,
                withSearch: true,
                withExport: true,
              }"
            ></table-actions-nav>
          </v-col>
        </v-row>

        <v-row class="uk-margin-small-top">
          <template>
            <v-data-table
              :headers="liveCoursesHeaders"
              :items="pendingCourses"
              :items-per-page="5"
              :search="search1"
              class="elevation-1 w-100"
              :footer-props="{
                itemsPerPageText: 'عدد الأسطر في الجدول:',
              }"
              :loading="loading"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <table-actions
                  :status="item.status"
                  :type="item.type"
                  @remove="remove(item.course_id)"
                  @edit="edit(item.course_id)"
                  @show="show(item.course_id)"
                  @courses="goTo(item.course_id)"
                  @offline="offline(item.course_id)"
                  course
                ></table-actions>
              </template>
            </v-data-table>
          </template>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="uk-container uk-margin-large-top">
      <v-col cols="12">
        <h1 class="font-weight-bold" :style="{ color: this.fontWeightBold }">
          الدورات النشطة
        </h1>
      </v-col>
      <v-col cols="12">
        <table-actions-nav
          type="course"
          v-model="search3"
          :data="{name: 'Live Courses', data: liveCourses}"
          :options="{ withAddNew: false, withSearch: true, withExport: true }"
        ></table-actions-nav>
      </v-col>
    </v-row>

    <v-row class="uk-margin-small-top">
      <template>
        <v-data-table
          :headers="liveCoursesHeaders"
          :items="liveCourses"
          :items-per-page="5"
          :search="search3"
          class="elevation-1 w-100"
          :footer-props="{
            itemsPerPageText: 'عدد الأسطر في الجدول:',
          }"
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <table-actions
              :status="item.status"
              :type="item.type"
              @remove="remove(item.course_id)"
              @edit="edit(item.course_id)"
              @show="show(item.course_id)"
              @courses="goTo(item.course_id)"
              @offline="offline(item.course_id)"
              course
            ></table-actions>
          </template>
        </v-data-table>
      </template>
    </v-row>
    <confirm-status
      v-if="statusConfirm >= 0"
      v-bind="{ id: statusConfirm, isOpen: true }"
      @handleClose="statusConfirm = -1"
      @handelRequest="resubmit"
    ></confirm-status>
  </div>
</template>
<script>
import TableActionsNav from "../../Components/TableActionsNav";
import TableActions from "../../Components/TableActions";
import BaseArrowBackIcon from "@/core/Base/Arrows/BaseArrowBackIcon";
import confirmStatus from './Pages/confirmStatus'

import { mapActions, mapGetters } from "vuex";

export default {
  name: "InstructorDashBoard",
  components: { TableActions, TableActionsNav, BaseArrowBackIcon, confirmStatus },
  data() {
    return {
      statusConfirm: -1,
      search1: "",
      search2: "",
      search3: "",
      loading: true,
      coursesHeaders: [
        {
          text: "اسم الدورة",
          align: "center",
          sortable: false,
          value: "course_ar_title",
        },
        { text: "الحالة", value: "status", align: "center" },
        { text: "النوع", value: "type", align: "center" },
        { text: "الرسالة", value: "message", align: "center" },
        { text: "", value: "actions", align: "center" },
      ],
      liveCoursesHeaders: [
        {
          text: "اسم الدورة",
          align: "center",
          sortable: false,
          value: "course_ar_title",
        },
        { text: "الحالة", value: "status", align: "center" },
        { text: "النوع", value: "type", align: "center" },
        { text: "", value: "actions", align: "center" },
      ],
      pendingCourses: [],
      liveCourses: [],
      adminCourses: [],
    };
  },
  methods: {
    ...mapActions(["fetchCourses", "deleteCourseInstructor", "resubmitCourse"]),

    init() {
      this.fetchCourses({ page: 0, size: 100, q: "", lang: "ar" }).then((res) => {
        console.log(res)
        this.pendingCourses = [];
        this.liveCourses = [];
        this.adminCourses = [];
        this.courses.forEach((course) => {
          let displayCourse = { ...course };
          displayCourse.status = this.translate(course.status);
          displayCourse.type = this.translate(course.type);
          if (course.status === "live") {
            this.liveCourses.push(displayCourse);
          } else if (course.status === "pending") {
            this.pendingCourses.push(displayCourse);
          } else {
            this.adminCourses.push(displayCourse);
          }
        });
      });
    },

    remove(id) {
      console.log(id);
      // console.log(this.id);
      this.deleteCourseInstructor(id).then(() => {
        this.init();
      });
    },
    edit(id) {
      console.log(id);
      this.$router.push({ name: "updateCourse", params: { id: id } });
    },
    show(id) {
      console.log(id);
      this.$router.push({ name: "showCourse", params: { id: id } });
    },
    goTo(id) {
      console.log(id);
      this.$router.push({ name: "modulesIndex", params: { id: id } });
    },
    offline(id) {
      console.log(id);
      this.$router.push({ name: "addOffline", params: { id: id } });
    },
    resubmit(payload) {
      console.log('resubmit',payload.id);
      this.resubmitCourse(payload.id).then(() => {
        this.statusConfirm = -1;
        this.init();
      });
    },
    translate(word) {
      switch (word) {
        case "live":
          return "نشط";
        case "denied":
          return "مرفوض";
        case "resubmit":
          return "إعادة الإرسال";
        case "pending":
          return "قيد الانتظار";
        case "online":
          return "عبر الإنترنت";
        case "offline":
          return "حضورية";
        default:
          return word;
      }
    },
  },
  computed: {
    ...mapGetters({ courses: "getCourses" }),
    export_things() {
      return {
        name: "Courses",
        fields: {
          "اسم الدورة": "course_ar_title",
          "الحالة ": "status",
          "النوع ": "type",
        },
        data: this.courses,
      };
    },
  },
  watch: {
    courses: function () {
      this.loading = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style >
.v-card {
  border-radius: 20px;
}

.v-data-table {
  width: 100%;
}
.v-application--is-rtl .v-data-footer__pagination {
  direction: ltr !important;
}
.v-data-table-header tr th {
  text-align: center !important;
}
</style>
